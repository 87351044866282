<template>
	<div class="performance performance--overview">
		<div class="performance__col performance__col--2">
			<div class="performance__content">
				<h2 class="performance__title">Total Number of <br> <span class="secondary-base">Securities <br>Transactions <br>Settled</span></h2>
			</div>
			<div class="performance__graphic">
				<number-block class="number-block--yellow" :numbers="totalVolumeSecurities" />
			</div>
		</div>
	</div>
</template>

<script>
import NumberBlock from '@/components/NumberBlock'

export default {
	name: 'SecuritiesSettled',
	components: {
		NumberBlock,
	},
	data: () => ({
		totalVolumeSecurities: [
			{
				title: '2020',
				value: 561,
				unit: 'Million',
			},
			{
				title: '2019',
				value: 654,
				unit: 'Million',
			},
			{
				title: '2018',
				value: 389,
				unit: 'Million',
			},
		],
	}),
}
</script>

<style scoped lang="scss">

</style>
